/******************************************************************
 * KKT-VITEALIST
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-vitealist {
  @include flattenList;
  &-entry {
    display: flex;
    @include ARTICLE_BLOCK_SECTION_MARGIN(margin-top);
    &-date {
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
      @include KKT_ARTICLE_P_SMALL_FONTSTYLE;
      color: $KKT_DARK_BLUE;
      flex-basis: 35%;
      text-align: right;
      box-sizing: border-box;
      padding-top: 2px;
      border-right: 2px solid $KKT_WHITE;
      @include RESPOND_TO($KKT_BREAKPOINT_SM) {
        flex-basis: 40%;
      }
    }
    &-content {
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
      @include KKT_ARTICLE_P_SMALL_FONTSTYLE;
      flex-basis: 65%;
      box-sizing: border-box;
      @include RESPOND_TO($KKT_BREAKPOINT_SM) {
        flex-basis: 60%;
      }
      h4 {
        @include KKT_ARTICLE_H4_FONTSTYLE;
      }
      h5 {
        @include KKT_ARTICLE_H5_FONTSTYLE;
        margin-bottom: 0.5rem;
      }
      &-text {
        @include KKT_ARTICLE_P_SMALL_FONTSTYLE;
      }
    }
  }
}
