/******************************************************************
 * KKT-CONTACT
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-contact {
  &-compact {
    position: relative;
    &-image {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-content {
      @include ARTICLE_SECTION_MARGIN(padding-top);
      @include ARTICLE_SECTION_MARGIN(padding-bottom);
      box-sizing: border-box;
      position: relative;
      .kkt-contactbox {
        margin: auto;
      }
    }
  }
  &-indiviual {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    @include ARTICLE_BLOCK_SECTION_MARGIN(gap);
    @include RESPOND_TO($KKT_BREAKPOINT_SM) {
      grid-template-columns: 1fr 1fr;
      @include ARTICLE_SECTION_ELEMENT_MARGIN(gap);
    }
    @include RESPOND_TO($KKT_BREAKPOINT_M) {
      grid-template-columns: 1fr 1fr;
      @include ARTICLE_BLOCK_SECTION_MARGIN(gap);
    }
    &-entry {
      position: relative;
      background-color: rgba($KKT_WHITE, 0.5);
      padding: 1rem;
      box-sizing: border-box;
      text-align: center;
      display: grid;
      &-title {
        position: absolute;
        left: 50%;
        top: 1rem;
        transform: translateX(-50%);
        @include KKT_ARTICLE_H5_FONTSTYLE;
      }
      &-content {
        align-self: center;
        justify-self: center;
        @include KKT_ARTICLE_H3_FONTSTYLE;
        @include ARTICLE_BLOCK_SECTION_MARGIN(padding-top, 0.75);
        @include ARTICLE_BLOCK_SECTION_MARGIN(padding-bottom, 0.75);
        &-addition {
          @include KKT_ARTICLE_H4_FONTSTYLE;
          margin-top: 0.5rem;
          color: $KKT_BROWN;
        }
      }
      &-cta {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
