/******************************************************************
 * Anchor in General
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

a {
  color: $KKT_GRAY;
  text-decoration: none;
}
