/******************************************************************
 * KKT-SERVICEINFOS
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-serviceinfos {
  @include flattenList;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &-entry {
    flex-basis: 100%;
    box-sizing: border-box;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
    @include RESPOND_TO($KKT_BREAKPOINT_SM) {
      flex-basis: 50%;
      max-width: 50%;
    }
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2) {
      @include RESPOND_TO($KKT_BREAKPOINT_SM) {
        margin-top: 0;
      }
    }
    &:nth-child(2n + 1) {
      @include RESPOND_TO($KKT_BREAKPOINT_SM) {
        @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
      }
    }
    &:nth-child(2n + 2) {
      @include RESPOND_TO($KKT_BREAKPOINT_SM) {
        @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
      }
    }
    &-content {
      background-color: rgba($KKT_WHITE, 0.5);
      box-sizing: border-box;
      position: relative;
      height: 100%;
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding);
      @include ARTICLE_FLUID_PROPERTY(padding-bottom, 30, 40, 40, 40);
      text-align: center;
      h4 {
        @include KKT_ARTICLE_H3_FONTSTYLE;
        hyphens: auto;
        &:after {
          content: " ";
          display: block;
          width: 100px;
          height: 2px;
          background-color: $KKT_LIGHT_BROWN;
          margin: 1rem auto;
        }
      }
      p {
        @include KKT_ARTICLE_P_SMALL_FONTSTYLE;
      }
      .kkt-quick-contact-buttons {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) translateY(50%);
      }
      &-cta {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) translateY(50%);
        @include KKT_LIGHT_LABEL_BUTTON;
      }
    }
  }
}
