/******************************************************************
 * Header-UI
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

#kkt-header-ui {
  position: relative;
  user-select: none;
  margin-top: $NAVIGATION_FRAME_PADDING;
  box-sizing: border-box;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  menu-button {
    position: absolute;
    left: $NAVIGATION_FRAME_PADDING;
    @include HOVER_SCALE_EFFECT
  }
  .kkt-quick-contact-buttons {
    position: absolute;
    right: $NAVIGATION_FRAME_PADDING;
  }
}
