@mixin KKT_MAIN_NAV_ITEM() {
  li {
    position: relative;
    &:first-child {
      border-top: 1px solid $KKT_LIGHT_BROWN;
    }
    a {
      border-bottom: 1px solid $KKT_LIGHT_BROWN;
      display: block;
      position: relative;
      &:hover {
        text-decoration: none;
      }
      span {
        @include KKT_MAIN_NAVIGATION_FONTSTYLE;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: $NAVIGATION_FRAME_PADDING;
        box-sizing: border-box;
        width: calc(100% + #{$NAVIGATION_FRAME_PADDING});
      }
    }
    .show-secondary-page-indicator {
      &:after {
        position: absolute;
        content: " ";
        background-image: url($KKT_SMALL_ARROW_ICON);
        width: 12px;
        height: 12px;
        top: calc(50% - 7px);
        right: 5px;
      }
    }
    .show-dotted-line {
      @include DRAW_DOTTED_LINE();
      border-bottom: unset;
    }
  }
  li.kkt-navigation-main-secondary-title {
    border-bottom: 1px solid $KKT_LIGHT_BROWN;
    padding-top: 50px;
    &:first-child {
      border-top: none;
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1em;
      pointer-events: none;
    }
    a {
      border: none;
      margin-bottom: 10px;
      span {
        @include KKT_MAIN_NAVIGATION_TITLE_FONTSTYLE;
      }
    }
  }
}
