/******************************************************************
 * KKT-OPENINGS
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-openings {
  position: relative;
  @include ARTICLE_FLUID_PROPERTY(padding-top, 250, 450, 450, 550);
  @include ARTICLE_BLOCK_SECTION_MARGIN(padding-bottom);
  &-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-content {
    position: relative;
    text-align: center;
    @include ARTICLE_TEXT_CONTENT_WIDTH;
    max-width: 550px;
    margin: auto;
    background-color: $KKT_BACKGROUND;
    @include ARTICLE_SECTION_ELEMENT_MARGIN(padding);
    @include ARTICLE_FLUID_PROPERTY(padding-bottom, 30, 40, 40, 60);
    h3 {
      @include KKT_ARTICLE_H3_FONTSTYLE;
      &:after {
        display: block;
        content: " ";
        height: 2px;
        width: 100px;
        margin: 1rem auto;
        background-color: $KKT_LIGHT_BROWN;
      }
    }
    h4 {
      @include KKT_ARTICLE_H4_FONTSTYLE;
    }
    .kkt-quick-contact-buttons {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}
