/******************************************************************
 * UTILS
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

/* ----------------------------------------------------------------
 * Functions
 * --------------------------------------------------------------*/

@function getResponsiveValueCalc($minValue, $maxValue, $minWidth: $KKT_BREAKPOINT_XS, $maxWidth: $KKT_BREAKPOINT_XXL) {
    @return calc(#{$minValue}px + #{$maxValue - $minValue} * (100vw - #{$minWidth}px) / #{$maxWidth - $minWidth});
}

/* ----------------------------------------------------------------
 * Mixins
 * --------------------------------------------------------------*/

@mixin FLUID_PROPERTY($property, $minValue, $maxValue, $minBaseWidth: $KKT_BREAKPOINT_XS, $maxBaseWidth: $KKT_BREAKPOINT_XXL) {
    #{$property}: #{$minValue}px;
    @include RESPOND_TO($minBaseWidth) {
        #{$property}: getResponsiveValueCalc($minValue, $maxValue, $minBaseWidth, $maxBaseWidth);
    }
    @include RESPOND_TO($maxBaseWidth) {
        #{$property}: #{$maxValue}px;
    }
}

@mixin CLEARFIX_AFTER {
    &:after {
        content: ".";
        clear: both;
        display: block;
        visibility: hidden;
        height: 0;
    }
}

/* ----------------------------------------------------------------
 * GFX
 * --------------------------------------------------------------*/

@mixin DRAW_DOTTED_LINE($color: $KKT_BROWN, $gap: 4px) {
  background-image: linear-gradient(to right, $color 20%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: $gap 1px;
  background-repeat: repeat-x;
}

/* ----------------------------------------------------------------
 * Easings
 * --------------------------------------------------------------*/

$EASE_IN_QUINT: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$EASE_OUT_QUINT: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$EASE_INOUT_QUINT: cubic-bezier(0.860, 0.000, 0.070, 1.000);
