/******************************************************************
 * KKT-IMAGEGALLERY
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-imagegallery {
  @include flattenList;
  &[data-layout="story"] {
    .kkt-imagegallery-entry {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: flex-start;
      @include ARTICLE_BLOCK_SECTION_MARGIN(margin-bottom);
      @include MOBILE_MODE {
        display: block;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
      &:nth-child(2n + 2) {
        .kkt-imagegallery-entry-image {
          order: 2;
        }
        .kkt-imagegallery-entry-content {
          text-align: right;
          @include MOBILE_MODE {
            text-align: left;
          }
        }
      }
      &-image {
        flex-basis: 50%;
        box-sizing: border-box;
        div {
          position: relative;
          padding-bottom: 100%;
          img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
      &-content {
        flex-basis: 50%;
        margin-top: 25%;
        box-sizing: border-box;
        @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
        @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
        @include MOBILE_MODE {
          @include ARTICLE_SECTION_ELEMENT_MARGIN(margin-top);
        }
        h4 {
          @include KKT_ARTICLE_H3_FONTSTYLE;
          margin-bottom: 1rem;
        }
        &-text {
          @include KKT_ARTICLE_P_FONTSTYLE;
        }
      }
    }
  }
  &[data-layout="publications"] {
    .kkt-imagegallery-entry {
      box-sizing: border-box;
      width: 50%;
      display: flex;
      @include MOBILE_MODE {
        width: 100%;
        max-width: 500px;
        @include ARTICLE_BLOCK_SECTION_MARGIN(margin-bottom);
      }
      &:nth-child(3n + 1) {
        @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
        margin-left: 50%;
        @include MOBILE_MODE {
          margin-left: auto;
          margin-right: auto;
        }
        .kkt-imagegallery-entry-image {
          @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
        }
        .kkt-imagegallery-entry-content {
        }
      }
      &:nth-child(3n + 2) {
        @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
        margin-top: -200px;
        @include MOBILE_MODE {
          margin-top: 0;
          margin-left: auto;
          margin-right: auto;
        }
        .kkt-imagegallery-entry-image {
          @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
          order: 2
        }
        .kkt-imagegallery-entry-content {
          text-align: right;
        }
      }
      &:nth-child(3n + 3) {
        @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
        @include ARTICLE_BLOCK_SECTION_MARGIN(margin-bottom);
        margin-left: 36%;
        @include MOBILE_MODE {
          margin-left: auto;
          margin-right: auto;
        }
        .kkt-imagegallery-entry-image {
          @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
        }
        .kkt-imagegallery-entry-content {
        }
      }
      &-image {
        flex-basis: 50%;
        box-sizing: border-box;
        @include ARTICLE_BLOCK_SECTION_MARGIN(margin-bottom);
        div {
          position: relative;
          width: 100%;
          padding-bottom: 140%;
          img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
      &-content {
        flex-basis: 50%;
        margin-top: 30%;
        h4 {
          @include KKT_ARTICLE_H4_FONTSTYLE;
          margin-bottom: 1rem;
        }
        &-text {
          @include KKT_ARTICLE_P_SMALL_FONTSTYLE;
        }
      }
    }
  }
}
