/******************************************************************
 * Body in General
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

body {
  position: relative;
  margin: 0;
  font-family: $KKT_FONT_FAMILY;
  background-color: $KKT_BACKGROUND;
  width: 100vw;
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
}

#kkt-homepage {
  position: fixed;
  height: 100%;
  overflow-y: hidden;
}

[data-is-develop-server] {
  &:before {
    content: "DEVELOP";
    position: fixed;
    top: 0;
    left: 0;
    font-size: 10px;
    color: $KKT_WHITE;
    font-weight: bold;
    background-color: red;
    padding: 2px 6px;
    z-index: 100;
  }
}
