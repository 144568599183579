/******************************************************************
 * KKT Homepage Teasers
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

$TEASER_BOTTOM_MARGIN_MAX: 75;
$TEASER_BOTTOM_MARGIN_MIN: 35;
$TEASER_ELEMENT_MARGIN_MAX: 30;
$TEASER_ELEMENT_MARGIN_MIN: 20;

.kkt-home-teasers {
  user-select: none;
  position: fixed;
  top: 0;
  left: $NAVIGATION_FRAME_WIDTH;
  width: calc(100vw - #{$NAVIGATION_FRAME_WIDTH});
  height: 100%;
  @include MOBILE_MODE {
    width: 100vw;
    left: 0;
  }
  .kkt-home-teaser {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .kkt-teaser-infobox {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 90%;
      @include FLUID_PROPERTY(max-width, 640, 750, $KKT_BREAKPOINT_L);
      margin-left: auto;
      margin-right: auto;
      @include FLUID_PROPERTY(margin-bottom, $TEASER_BOTTOM_MARGIN_MIN, $TEASER_BOTTOM_MARGIN_MAX);
      @include FLUID_PROPERTY(padding, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
      box-sizing: border-box;
      text-align: center;
      background-color: $KKT_BACKGROUND;
      h1, h2 {
        @include KKT_MAIN_TEASER_HEADLINE_FONTSTYLE;
        &:after {
          content: "";
          display: block;
          width: 100px;
          height: 3px;
          margin-left: auto;
          margin-right: auto;
          background: $KKT_WHITE;
          @include FLUID_PROPERTY(margin-top, $TEASER_ELEMENT_MARGIN_MIN * 0.5, $TEASER_ELEMENT_MARGIN_MAX * 0.5);
        }
      }
      p {
        @include KKT_FIGURE_P_FONTSTYLE;
        @include FLUID_PROPERTY(margin-top, $TEASER_ELEMENT_MARGIN_MIN * 0.5, $TEASER_ELEMENT_MARGIN_MAX * 0.5);
        @include FLUID_PROPERTY(margin-bottom, $TEASER_ELEMENT_MARGIN_MIN * 0.5, $TEASER_ELEMENT_MARGIN_MAX * 0.5);
      }
      div {
        position: absolute;
        bottom: 0;
        left: 50%;
        .kkt-teaser-infobox-cta {
          @include KKT_DARK_LABEL_BUTTON(false);
          position: absolute;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }

    .kkt-teaser-quote {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-image: linear-gradient(180deg, rgba($KKT_BACKGROUND, 0) 0%, rgba($KKT_BACKGROUND, 0.95) 65%);
      @include FLUID_PROPERTY(padding-top, 50, 100);
      @include FLUID_PROPERTY(padding-bottom, $TEASER_BOTTOM_MARGIN_MIN, $TEASER_BOTTOM_MARGIN_MAX);
      text-align: center;
      h1, h2 {
        @include KKT_MAIN_TEASER_QUOTE_FONTSTYLE;
        width: 90%;
        max-width: 640px;
        @include FLUID_PROPERTY(max-width, 300, 900);
        padding-top: 30px;
        @include FLUID_PROPERTY(padding-left, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
        @include FLUID_PROPERTY(padding-right, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
        @include FLUID_PROPERTY(margin-bottom, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
        box-sizing: border-box;
        background-image: url($KKT_QUOTE_ICON);
        background-repeat: no-repeat;
        margin-left: auto;
        margin-right: auto;
      }
      &-authorname {
        @include KKT_FIGURE_H_FONTSTYLE;
        max-width: 450px;
        width: 90%;
        @include FLUID_PROPERTY(padding-left, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
        @include FLUID_PROPERTY(padding-right, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
        box-sizing: border-box;
        margin: auto;
      }
      &-authorinfo {
        @include KKT_FIGURE_P_FONTSTYLE;
        max-width: 450px;
        width: 90%;
        @include FLUID_PROPERTY(padding-left, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
        @include FLUID_PROPERTY(padding-right, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
        box-sizing: border-box;
        margin: auto;
      }
      &-cta {
        @include KKT_DARK_LABEL_BUTTON(false);
        @include FLUID_PROPERTY(margin-top, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
      }
    }
    .kkt-contactbox {
      position: absolute;
      top: 53%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

}
