/******************************************************************
 * Paragraph in General
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

p {
  padding: 0;
  margin: 0;
}
