.kkt-reviewstars {
  font-size: 0;
  position: relative;
  display: inline-block;
  span {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    width: 10px;
    height: 10px;
    margin-right: 0.15rem;
    @include RESPOND_TO($KKT_BREAKPOINT_SM) {
      width: 15px;
      height: 15px;
      margin-right: 0.2rem;
    }
  }
  &-foreground {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    // width is set in reviewstars.php
  }
  &-selected {
    background-image: url($KKT_REVIEW_STAR_BLUE);
  }
  &-empty {
    background-image: url($KKT_REVIEW_STAR_WHITE);
  }
}

.kkt-reviewstars-theme-white {
  .kkt-reviewstars-selected {
    background-image: url($KKT_REVIEW_STAR_WHITE);
  }
  .kkt-reviewstars-empty {
    opacity: 0.2;
    background-image: url($KKT_REVIEW_STAR_WHITE);
  }
}

.kkt-reviewstars-theme-blue {
  .kkt-reviewstars-selected {
    background-image: url($KKT_REVIEW_STAR_BLUE);
  }
  .kkt-reviewstars-empty {
    background-image: url($KKT_REVIEW_STAR_WHITE);
  }
}
