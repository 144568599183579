cookie-disclaimer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($KKT_BACKGROUND, 0.8);
  z-index: 1000;
  display: grid !important;
  justify-content: center;
  align-content: center;
  .kkt-cookie-disclaimer-window {
    text-align: center;
    max-width: 90vw;
    width: 600px;
    background-color: $KKT_DARK_BLUE;
    box-sizing: border-box;
    border-radius: 10px;
    @include FLUID_PROPERTY(padding, 20, 30);
    div {
      @include KKT_ARTICLE_P_SMALL_FONTSTYLE;
      color: $KKT_BACKGROUND;
    }
    &-buttons {
      display: grid;
      grid-template-columns: auto 1fr 1fr auto;
      justify-items: center;
      @include FLUID_PROPERTY(margin-top, 20, 30);
      &-deny {
        grid-column: 2/span 1;
        @include KKT_LIGHT_LABEL_BUTTON;
      }
      &-accept {
        grid-column: 3/span 1;
        @include KKT_LIGHT_LABEL_BUTTON;
      }
    }
  }
}
