/******************************************************************
 * KKT-IMAGE
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-image {
  div {
    position: relative;
    width: 100%;
    padding-bottom: 58%;
    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  figcaption {
    @include KKT_ARTICLE_P_SMALL_FONTSTYLE;
    @include ARTICLE_TEXT_CONTENT_WIDTH;
    margin-top: 0.5rem;
    margin-left: 0;
    color: $KKT_BROWN;
  }
}
