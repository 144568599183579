/******************************************************************
 * Breakpoints
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

// IMPORTANT: keep in sync with ts/style/Size.ts
$KKT_BREAKPOINT_XS: 320;
$KKT_BREAKPOINT_S: 400;
$KKT_BREAKPOINT_SM: 550;
$KKT_BREAKPOINT_M: 768;
$KKT_BREAKPOINT_L: 1024;
$KKT_BREAKPOINT_XL: 1440;
$KKT_BREAKPOINT_XXL: 1920;

@mixin RESPOND_TO($breakpoint) {
    @media (min-width: #{$breakpoint}px) {
        @content;
    }
}

@mixin MOBILE_MODE() {
  @media (max-width: #{$KKT_BREAKPOINT_M}px) {
    @content;
  }
}
