@mixin HOVER_EFFECT() {
  -webkit-tap-highlight-color: transparent;
  &:active {
    @content;
  }
  @media (hover: hover) {
    &:hover {
      @content
    }
  }
}

@mixin HOVER_SCALE_EFFECT($scale: 1.1) {
  transition: transform 0.3s $EASE_OUT_QUINT;
  @include HOVER_EFFECT() {
    transform: scale($scale);
  }
}
