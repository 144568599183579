/******************************************************************
 * List in General
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

@mixin flattenList() {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
