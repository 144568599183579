/******************************************************************
 * KKT-REVIEWS
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-reviews {
  @include flattenList;
  &-entry-google {
    box-sizing: border-box;
    width: 50%;
    display: flex;
    justify-content: center;
    @include MOBILE_MODE {
      width: 100%;
      max-width: 500px;
      @include ARTICLE_BLOCK_SECTION_MARGIN(margin-bottom);
    }
    &:nth-child(3n + 1) {
      margin-left: 50%;
      @include MOBILE_MODE {
        margin-left: auto;
        margin-right: auto;
      }
    }
    &:nth-child(3n + 2) {
      margin-top: -150px;
      @include MOBILE_MODE {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
      }
      .kkt-reviews-entry-google-content {
        text-align: right;
      }
    }
    &:nth-child(3n + 3) {
      @include ARTICLE_BLOCK_SECTION_MARGIN(margin-bottom);
      margin-left: 36%;
      @include MOBILE_MODE {
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-content {
      flex-basis: 50%;
      margin-top: 15%;
      position: relative;
      pointer-events: none;
      .kkt-reviewstars {
        margin-bottom: 1rem;
      }
      blockquote {
        a {
          pointer-events: all;
        }
        p {
          @include KKT_ARTICLE_H4_FONTSTYLE;
          margin-bottom: 1rem;
          position: relative;
          font-style: italic;
          &:before {
            content: " ";
            position: absolute;
            background-image: url($KKT_QUOTE_ICON);
            background-repeat: no-repeat;
            background-size: contain;
            transform: translate(-50%, -50%);
            width: 70px;
            height: 50px;
          }
        }
        div {
          @include KKT_FIGURE_H_FONTSTYLE;
          color: $KKT_BROWN;
        }
      }
    }
  }
  &-entry-stories {
    box-sizing: border-box;
    width: 50%;
    display: flex;
    @include MOBILE_MODE {
      width: 100%;
      max-width: 500px;
      @include ARTICLE_BLOCK_SECTION_MARGIN(margin-bottom);
    }
    &:nth-child(3n + 1) {
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
      margin-left: 50%;
      @include MOBILE_MODE {
        margin-left: auto;
        margin-right: auto;
      }
      .kkt-reviews-entry-stories-image {
        @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
      }
    }
    &:nth-child(3n + 2) {
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
      margin-top: -200px;
      @include MOBILE_MODE {
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
      }
      .kkt-reviews-entry-stories-image {
        @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
        order: 2
      }
      .kkt-reviews-entry-stories-content {
        text-align: right;
      }
    }
    &:nth-child(3n + 3) {
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
      @include ARTICLE_BLOCK_SECTION_MARGIN(margin-bottom);
      margin-left: 36%;
      @include MOBILE_MODE {
        margin-left: auto;
        margin-right: auto;
      }
      .kkt-reviews-entry-stories-image {
        @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
      }
    }
    &-image {
      flex-basis: 50%;
      display: block;
      box-sizing: border-box;
      @include ARTICLE_BLOCK_SECTION_MARGIN(margin-bottom);
      div {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        a.kkt-reviews-entry-stories-image-cta {
          position: absolute;
          width: 100%;
          height: 100%;
          overflow: hidden;
          @media (hover: hover) {
            &:hover {
              img {
                transform: scale(1.05);
              }
            }
          }
          &:active {
            img {
              transform: scale(1.05);
            }
          }
          img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform 1s $EASE_OUT_QUINT;
          }
        }
        a.kkt-reviews-entry-stories-button-cta {
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%) translateY(50%);
          @include KKT_LIGHT_LABEL_BUTTON;
        }
      }
    }
    &-content {
      flex-basis: 50%;
      margin-top: 25%;
      position: relative;
      pointer-events: none;
      .kkt-reviewstars {
        margin-bottom: 1rem;
      }
      blockquote {
        p {
          @include KKT_ARTICLE_H4_FONTSTYLE;
          margin-bottom: 1rem;
          position: relative;
          font-style: italic;
          &:before {
            content: " ";
            position: absolute;
            background-image: url($KKT_QUOTE_ICON);
            background-repeat: no-repeat;
            background-size: contain;
            transform: translate(-50%, -50%);
            width: 70px;
            height: 50px;
          }
        }
        div {
          @include KKT_FIGURE_H_FONTSTYLE;
          color: $KKT_BROWN;
        }
      }
    }
  }
}
