/******************************************************************
 * FontStyles
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/


/* ----------------------------------------------------------------
 * NAVIGATION
 * --------------------------------------------------------------*/

@mixin KKT_MAIN_NAVIGATION_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  color: $KKT_BROWN;
  @include FLUID_PROPERTY(font-size, 17, 17);
  @include FLUID_PROPERTY(line-height, 21, 21);
}

@mixin KKT_MAIN_NAVIGATION_TITLE_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  color: $KKT_BROWN;
  @include FLUID_PROPERTY(font-size, 24, 24);
  @include FLUID_PROPERTY(line-height, 27, 27);
}

@mixin KKT_MAIN_NAVIGATION_REVIEW_QUOTE_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  @include FLUID_PROPERTY(font-size, 15, 15);
  @include FLUID_PROPERTY(line-height, 20, 20);
}

/* ----------------------------------------------------------------
 * HOME TEASER
 * --------------------------------------------------------------*/

@mixin KKT_MAIN_TEASER_HEADLINE_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  color: $KKT_SEMI_BLUE;
  @include FLUID_PROPERTY(font-size, 24, 38);
  @include FLUID_PROPERTY(line-height, 30, 47);
}

@mixin KKT_MAIN_TEASER_QUOTE_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  font-style: italic;
  color: $KKT_SEMI_BLUE;
  @include FLUID_PROPERTY(font-size, 22, 38);
  @include FLUID_PROPERTY(line-height, 28, 47);
}

/* ----------------------------------------------------------------
 * FIGURE
 * --------------------------------------------------------------*/

@mixin KKT_FIGURE_H_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_SEMIBOLD;
  color: $KKT_GRAY;
  @include FLUID_PROPERTY(font-size, 16, 18);
  @include FLUID_PROPERTY(line-height, 22, 26);
}

@mixin KKT_FIGURE_P_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  color: $KKT_GRAY;
  @include FLUID_PROPERTY(font-size, 16, 18);
  @include FLUID_PROPERTY(line-height, 22, 26);
}

/* ----------------------------------------------------------------
 * ARTICLE
 * --------------------------------------------------------------*/

@mixin KKT_ARTICLE_H1_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_EXTRALIGHT;
  color: $KKT_DARK_BLUE;
  @include ARTICLE_FLUID_PROPERTY(font-size, 42, 60, 60, 85);
  @include ARTICLE_FLUID_PROPERTY(line-height, 45, 65, 65, 90);
}

@mixin KKT_ARTICLE_H2_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_EXTRALIGHT;
  color: $KKT_DARK_BLUE;
  @include ARTICLE_FLUID_PROPERTY(font-size, 35, 45, 45, 60);
  @include ARTICLE_FLUID_PROPERTY(line-height, 40, 50, 50, 65);
}

@mixin KKT_ARTICLE_H3_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  color: $KKT_DARK_BLUE;
  @include ARTICLE_FLUID_PROPERTY(font-size, 20, 26, 26, 28);
  @include ARTICLE_FLUID_PROPERTY(line-height, 30, 36, 36, 38);
}

@mixin KKT_ARTICLE_H4_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  color: $KKT_DARK_BLUE;
  @include ARTICLE_FLUID_PROPERTY(font-size, 17, 20, 20, 22);
  @include ARTICLE_FLUID_PROPERTY(line-height, 22, 26, 28, 30);
}

@mixin KKT_ARTICLE_H5_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_REGULAR;
  color: $KKT_GRAY;
  @include ARTICLE_FLUID_PROPERTY(font-size, 12, 16, 16, 16);
  @include ARTICLE_FLUID_PROPERTY(line-height, 20, 24, 24, 24);
}

@mixin KKT_ARTICLE_ABSTRACT_HEADING_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  color: $KKT_BROWN;
  @include ARTICLE_FLUID_PROPERTY(font-size, 20, 24, 24, 24);
  @include ARTICLE_FLUID_PROPERTY(line-height, 26, 34, 34, 34);
}

@mixin KKT_ARTICLE_QUOTE_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_EXTRALIGHT;
  color: $KKT_DARK_BLUE;
  font-style: italic;
  @include ARTICLE_FLUID_PROPERTY(font-size, 25, 40, 40, 50);
  @include ARTICLE_FLUID_PROPERTY(line-height, 35, 50, 50, 60);
}

@mixin KKT_ARTICLE_P_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  color: $KKT_GRAY;
  @include ARTICLE_FLUID_PROPERTY(font-size, 17, 18, 18, 20);
  @include ARTICLE_FLUID_PROPERTY(line-height, 26, 28, 28, 36);
}

@mixin KKT_ARTICLE_P_SMALL_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  color: $KKT_GRAY;
  @include ARTICLE_FLUID_PROPERTY(font-size, 14, 16, 16, 16);
  @include ARTICLE_FLUID_PROPERTY(line-height, 19, 24, 24, 24);
}

@mixin KKT_ARTICLE_FOOTNOTE_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_REGULAR;
  color: $KKT_BROWN;
  @include ARTICLE_FLUID_PROPERTY(font-size, 10, 12, 12, 12);
  @include ARTICLE_FLUID_PROPERTY(line-height, 13, 15, 15, 15);
}

/* ----------------------------------------------------------------
 * UI
 * --------------------------------------------------------------*/

@mixin KKT_CONTACTBUTTON_LABEL_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_LIGHT;
  color: $KKT_GRAY;
  @include FLUID_PROPERTY(font-size, 14, 18);
  @include FLUID_PROPERTY(line-height, 20, 26);
}

@mixin KKT_LABELBUTTON_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_SEMIBOLD;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: $KKT_BROWN;
  @include FLUID_PROPERTY(font-size, 12, 14);
  @include FLUID_PROPERTY(line-height, 12, 14);
}

/* ----------------------------------------------------------------
 * FOOTER
 * --------------------------------------------------------------*/

@mixin KKT_FOOTER_FONTSTYLE {
  font-family: $KKT_FONT_FAMILY;
  font-weight: $KKT_FONT_WEIGHT_REGULAR;
  color: $KKT_BROWN;
  @include FLUID_PROPERTY(font-size, 12, 12);
  @include FLUID_PROPERTY(line-height, 12, 12);
}
