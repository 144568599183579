/******************************************************************
 * KKT-FACTLIST
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-factlist {
  @include flattenList;
  &-entry {
    display: flex;
    background-color: rgba($KKT_WHITE, 0.5);
    box-sizing: border-box;
    margin-top: 0.5em;
    &-title {
      flex-basis: 45%;
      box-sizing: border-box;
      padding: 1em 0.5em 1em 1.5em;
      @include KKT_ARTICLE_H4_FONTSTYLE;
    }
    &-content {
      @include KKT_ARTICLE_P_FONTSTYLE;
      flex-basis: 55%;
      hyphens: auto;
      -webkit-hyphens: auto;
      padding: 1em 1.5em 1em 0.5em;
      box-sizing: border-box;
    }
  }
}
