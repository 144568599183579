/******************************************************************
 * Colors
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

$KKT_WHITE: #FFFFFF;
$KKT_BACKGROUND: #F4F0ED;
$KKT_LIGHT_BROWN: #E7E0DB;
$KKT_BROWN: #78655e;
$KKT_GRAY: #3d3d3d;
$KKT_DARK_BLUE: #2F4858;
$KKT_SEMI_BLUE: #606376;
