.kkt-quote {
  text-align: center;
  &-text {
    position: relative;
    @include KKT_ARTICLE_QUOTE_FONTSTYLE;
    @include FLUID_PROPERTY(margin-bottom, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
    &:before {
      position: absolute;
      content: " ";
      width: 81px;
      height: 54px;
      background-image: url($KKT_QUOTE_ICON);
      background-repeat: no-repeat;
      top: -20px;
      left: -10px;
    }
  }
  &-author {
    @include KKT_FIGURE_H_FONTSTYLE;
    @include ARTICLE_TEXT_CONTENT_WIDTH;
    max-width: 450px;
    @include FLUID_PROPERTY(padding-left, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
    @include FLUID_PROPERTY(padding-right, $TEASER_ELEMENT_MARGIN_MIN, $TEASER_ELEMENT_MARGIN_MAX);
    box-sizing: border-box;
    margin: auto;
  }
}
