/******************************************************************
 * FontFaces
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap');

$KKT_FONT_FAMILY: 'Source Sans Pro', sans-serif;

$KKT_FONT_WEIGHT_EXTRALIGHT: 200;
$KKT_FONT_WEIGHT_LIGHT: 300;
$KKT_FONT_WEIGHT_REGULAR: 400;
$KKT_FONT_WEIGHT_SEMIBOLD: 600;
$KKT_FONT_WEIGHT_BOLD: 700;

// TODO: check this embedded font face and why this is not available
//@font-face {
//  font-family: $KKT_FONT_FAMILY;
//  font-weight: 300;
//  src: url('../fonts/Source_Sans_Pro/SourceSansPro-Light.ttf') format("truetype");
//}
//
//@font-face {
//  font-family: $KKT_FONT_FAMILY;
//  font-weight: 400;
//  font-style: normal;
//  src: url('../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format("truetype");
//}
//
//@font-face {
//  font-family: $KKT_FONT_FAMILY;
//  font-weight: 700;
//  src: url('../fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf') format("truetype");
//}

