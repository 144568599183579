/******************************************************************
 * KKT-ARTICLE-HEADER
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-article-header-negativ-marginbottom {
  @include FLUID_PROPERTY(margin-bottom, -30, -100);
}

#kkt-article-header {
  .kkt-article-header-image {
    @include FLUID_PROPERTY(height, 320, 800);
    position: relative;
    user-select: none;
    pointer-events: none;
    z-index: -1;
    overflow: hidden;
    &:after {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background: linear-gradient(180deg, rgba($KKT_BACKGROUND, 0) 0%, rgba($KKT_BACKGROUND, 1) 100%);
    }
    img {
      display: block;
      position: absolute;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .kkt-article-header-content {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
    @include FLUID_PROPERTY(margin-top, -30, -100);
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    @include ARTICLE_FLUID_PROPERTY(row-gap, 30, 50, 50, 100);
    grid-template-areas: "headline headline" "nav abstract";
    @include MOBILE_MODE {
      grid-template-columns: 1fr;
      grid-template-areas: "headline" "nav" "abstract";
    }
    &-headline {
      grid-area: headline;
      h1 {
        @include KKT_ARTICLE_H1_FONTSTYLE;
        max-width: 75%;
        text-shadow: 0 0 20px rgba($KKT_BACKGROUND, 1),
        0 0 30px rgba($KKT_BACKGROUND, 1),
        0 0 40px rgba($KKT_BACKGROUND, 1),
        0 0 50px rgba($KKT_BACKGROUND, 1);
        @include MOBILE_MODE {
          max-width: 100%;
          &[data-autohypens="true"] {
            hyphens: auto;
            -webkit-hyphens: auto;
          }
          &[data-autohypens="false"] {
            hyphens: manual;
            -webkit-hyphens: manual;
          }
        }
      }
      h2 {
        @include ARTICLE_SECTION_ELEMENT_MARGIN;
        @include KKT_ARTICLE_ABSTRACT_HEADING_FONTSTYLE;
        width: 90%;
        max-width: 650px;
      }
    }
    &-nav {
      grid-area: nav;
      nav {
        ul {
          @include flattenList;
          @include KKT_MAIN_NAV_ITEM();
          max-width: 250px;
        }
      }
    }
    &-abstract {
      grid-area: abstract;
      @include KKT_WRITER_STYLE;
      @include ARTICLE_FLUID_PROPERTY(padding, 20, 40, 40, 70);
      box-sizing: border-box;
      background-color: rgba($KKT_WHITE, 0.8);
      > p {
        margin-top: 1em;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
