/******************************************************************
 * KKT-PATIENTTEASERS
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-patientteasers {
  @include flattenList;
  &-entry {
    width: 50%;
    box-sizing: border-box;
    @include ARTICLE_FLUID_PROPERTY(margin-top, -65, -200, -200, -300);
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2n + 1) {
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
      margin-left: 50%;
    }
    &:nth-child(2n + 2) {
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
    }
    @media (hover: hover) {
      &:hover {
        .kkt-patientteasers-entry-image {
          opacity: 1;
          text-decoration: none;
          img {
            transform: scale(1.05);
          }
        }
      }
    }
    &:active {
      .kkt-patientteasers-entry-image {
        opacity: 1;
        text-decoration: none;
        img {
          transform: scale(1.05);
        }
      }
    }
    a {
      text-decoration: none !important;
    }
    &-image {
      position: relative;
      padding-bottom: 100%;
      opacity: 0.6;
      transition: opacity 0.2s $EASE_OUT_QUINT;
      overflow: hidden;
      img {
        position: absolute;
        object-fit: cover;
        top: 0;
        width: 100%;
        height: 100%;
        transition: transform 1s $EASE_OUT_QUINT;
      }
    }
    &-content {
      position: relative;
      text-align: center;
      border: 1px solid $KKT_WHITE;
      border-top-color: transparent;
      box-sizing: border-box;
      width: calc(100% - 10px);
      margin-left: auto;
      margin-right: auto;
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding);
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      @include RESPOND_TO($KKT_BREAKPOINT_SM) {
        @include ARTICLE_BLOCK_SECTION_MARGIN(padding);
        width: calc(100% - 20px);
      }
      &-name {
        @include KKT_ARTICLE_H4_FONTSTYLE;
        @include RESPOND_TO($KKT_BREAKPOINT_SM) {
          @include KKT_ARTICLE_H3_FONTSTYLE;
        }
      }
      &-operation {
        @include KKT_ARTICLE_H5_FONTSTYLE;
        @include RESPOND_TO($KKT_BREAKPOINT_SM) {
          @include KKT_ARTICLE_H5_FONTSTYLE;
        }
      }
      &-cta {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%) translateY(-50%);
        @include KKT_LIGHT_LABEL_BUTTON;
      }
    }
  }
}
