/******************************************************************
 * Blockquote in General
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

blockquote {
  margin: 0;
  padding: 0;
}
