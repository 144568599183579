/******************************************************************
 * Headings in General
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  hyphens: auto;
  -webkit-hyphens: auto;
  @include RESPOND_TO($KKT_BREAKPOINT_S) {
    hyphens: none;
    -webkit-hyphens: none;
  }
}
