/******************************************************************
 * KKT-FOOTER
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

#kkt-footer {
  @include ARTICLE_SECTION_MARGIN;
  background-color: $KKT_WHITE;
  @include ARTICLE_BLOCK_SECTION_MARGIN(padding-top);
  @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-bottom);
  .kkt-footer-logo {
    text-align: center;
    img {
      max-width: 60%;
    }
  }
  .kkt-footer-contact {
    @include ARTICLE_BLOCK_SECTION_MARGIN;
    @include ARTICLE_TEXT_CONTENT_WIDTH;
    @include KKT_ARTICLE_P_SMALL_FONTSTYLE;
    text-align: center;
  }
  .kkt-footer-meta {
    @include KKT_ARTICLE_P_SMALL_FONTSTYLE;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
    display: flex;
    @include MOBILE_MODE {
      display: block;
    }
    &-copyright {
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-left);
      flex-basis: 50%;
      order: 0;
      @include MOBILE_MODE {
        text-align: center;
        padding-left: 0;
        @include ARTICLE_SECTION_ELEMENT_MARGIN;
      }
    }
    &-links {
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-right);
      flex-basis: 50%;
      order: 1;
      text-align: right;
      @include MOBILE_MODE {
        text-align: center;
        padding-right: 0;
      }
      a {
        margin-left: 1rem;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.kkt-footer-no-margintop {
  margin-top: 0 !important;
}
