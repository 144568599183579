@mixin KKT_DARK_LABEL_BUTTON($useSmallSize: true) {
  @include KKT_LABELBUTTON_FONTSTYLE;
  @include _KKT_DARK_LABEL_BUTTON_PADDING($useSmallSize, false);
  user-select: none;
  -webkit-touch-callout: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $KKT_DARK_BLUE;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.05);
  pointer-events: all;
  color: $KKT_WHITE;
  transition: padding 0.2s $EASE_OUT_QUINT;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    text-decoration: none;
  }
  &:active {
    color: rgba($KKT_WHITE, 0.8);
    @include _KKT_DARK_LABEL_BUTTON_PADDING($useSmallSize, true);
  }
  @media (hover: hover) {
    &:hover {
      @include _KKT_DARK_LABEL_BUTTON_PADDING($useSmallSize, true);
    }
  }
}

@mixin KKT_LIGHT_LABEL_BUTTON($useSmallSize: true) {
  @include KKT_DARK_LABEL_BUTTON($useSmallSize);
  background-color: $KKT_WHITE;
  color: $KKT_DARK_BLUE;
  &:active {
    color: rgba($KKT_DARK_BLUE, 0.8);
  }
}

@mixin _KKT_DARK_LABEL_BUTTON_PADDING($useSmallSize, $isHover) {
  @if $useSmallSize {
    @if $isHover {
      padding: 5px 35px;
    } @else {
      padding: 5px 30px;
    }
    @include MOBILE_MODE {
      @if $isHover {
        padding: 5px 20px;
      } @else {
        padding: 5px 15px;
      }
    }
  } @else {
    @if $isHover {
      padding: 10px 45px;
    } @else {
      padding: 10px 40px;
    }
  }
}
