/******************************************************************
 * KKT-LOGOGRID
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-logogrid {
  @include flattenList;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  @include ARTICLE_BLOCK_SECTION_MARGIN(gap);
  @include RESPOND_TO($KKT_BREAKPOINT_S) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include RESPOND_TO($KKT_BREAKPOINT_M) {
    grid-template-columns: repeat(4, 1fr);
  }
  &-entry {
    overflow: hidden;
    &-logo {
      @include ARTICLE_FLUID_PROPERTY(max-width, 75, 100, 140, 140);
      @include ARTICLE_FLUID_PROPERTY(margin-bottom, 10, 20, 20, 25);
      margin: auto;
      div {
        position: relative;
        margin: auto;
        padding-bottom: 66%;
        &[data-blendmode="multiply"] {
          background-color: $KKT_BACKGROUND;
          img {
            mix-blend-mode: multiply;
          }
        }
        img {
          position: absolute;
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
    &-name {
      text-align: center;
      max-width: 150px;
      margin: auto;
      @include KKT_ARTICLE_FOOTNOTE_FONTSTYLE;
    }
  }
}
