/******************************************************************
 * Sizes
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

/* ----------------------------------------------------------------
 * NAVIGATION
 * --------------------------------------------------------------*/

$NAVIGATION_FRAME_PADDING: 20px;
$NAVIGATION_FRAME_WIDTH: 240px;

/* ----------------------------------------------------------------
 * SPACES
 * --------------------------------------------------------------*/

$GLOBAL_FRAME_SPACE: 5px;

/* ----------------------------------------------------------------
 * ARTICLE
 * --------------------------------------------------------------*/

@mixin ARTICLE_FLUID_PROPERTY($property, $minMobileValue, $maxMobileValue, $minDesktopValue, $maxDesktopValue) {
  @include FLUID_PROPERTY($property, $minDesktopValue, $maxDesktopValue, $KKT_BREAKPOINT_M, $KKT_BREAKPOINT_XXL);
  @include MOBILE_MODE {
    @include FLUID_PROPERTY($property, $minMobileValue, $maxMobileValue, $KKT_BREAKPOINT_XS, $KKT_BREAKPOINT_M);
  }
}

@mixin ARTICLE_SECTION_ELEMENT_MARGIN($property: margin-top) {
  @include ARTICLE_FLUID_PROPERTY($property, 15, 30, 30, 35);
}

@mixin ARTICLE_BLOCK_SECTION_MARGIN($property: margin-top, $scale: 1) {
  @include ARTICLE_FLUID_PROPERTY($property,
          $scale * 50,
          $scale * 60,
          $scale * 60,
          $scale * 80
  );
}

@mixin ARTICLE_SECTION_MARGIN($property: margin-top, $scale: 1) {
  @include ARTICLE_FLUID_PROPERTY($property,
          $scale * 80,
          $scale * 100,
          $scale * 100,
          $scale * 130
  );
}

@mixin ARTICLE_DEFAULT_CONTENT_WIDTH($property: width) {
  @include ARTICLE_FLUID_PROPERTY($property, 280, 706, 706, 1400);
  margin-left: auto;
  margin-right: auto;
}

@mixin ARTICLE_TEXT_CONTENT_WIDTH($property: width) {
  @include ARTICLE_FLUID_PROPERTY($property, 280, 600, 600, 850);
  margin-left: auto;
  margin-right: auto;
}
