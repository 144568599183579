/******************************************************************
 * Language Selector Styles
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-language-selector {
  position: absolute;
  user-select: none;
  left: calc(#{$NAVIGATION_FRAME_WIDTH + $NAVIGATION_FRAME_PADDING});
  opacity: 0;
  a {
    @include KKT_LABELBUTTON_FONTSTYLE;
    @include META_BUTTON_SHADOW_EFFECT;
    @include HOVER_SCALE_EFFECT;
    position: absolute;
    top: 0;
    color: $KKT_DARK_BLUE;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: $KKT_WHITE;
    text-transform: uppercase;
    opacity: 0;
    z-index: 0;
    &[data-is-current-lang="true"] {
      z-index: 2;
      opacity: 1;
    }
  }
}
