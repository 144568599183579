/******************************************************************
 * KKT-ACCORDION
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-accordion {
  @include flattenList;
  [data-state="opened"] {
    background-color: rgba($KKT_WHITE, 0.9);
    .kkt-accordion-entry-content {
      height: auto; // will be updated/animated by AccordionController.ts
    }
    .kkt-accordion-entry-cta {
      pointer-events: all;
    }
  }
  &-entry {
    transition: background-color 0.2s;
    background-color: rgba($KKT_WHITE, 0.5);
    margin-top: 0.75rem;
    position: relative;
    &-title {
      @include KKT_ARTICLE_H3_FONTSTYLE;
      padding: 1.2rem;
      @include ARTICLE_BLOCK_SECTION_MARGIN(padding-right);
      box-sizing: border-box;
      color: $KKT_SEMI_BLUE;
      transition: box-shadow 0.2s, background-color 0.2s;
      @include HOVER_EFFECT {
        color: $KKT_DARK_BLUE;
        background-color: $KKT_WHITE;
        @include EASY_BOX_SHADOW_EFFECT;
      }
    }
    &-content {
      height: 0; // will be updated/animated by AccordionController.ts
      overflow: hidden;
      &-desc {
        @include KKT_WRITER_STYLE;
        padding: 2rem 2rem;
        padding-top: 1rem;
        box-sizing: border-box;
        @include MOBILE_MODE {
          padding: 1.2rem;
          padding-top: 1rem;
        }
      }
    }
    &-cta {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      pointer-events: none;
      opacity: 0; // will be updated/animated by AccordionController.ts
    }
  }
}

.kkt-accordion-wide {
  .kkt-accordion-entry {
    &-content {
      &-desc {
        @include ARTICLE_TEXT_CONTENT_WIDTH(max-width);
        padding-left: 0;
        padding-right: 0;
        @include MOBILE_MODE {
          padding: 1.2rem;
          padding-top: 1rem;
        }
      }
    }
  }
}
