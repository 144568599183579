.kkt-google-review-stars {
  text-align: center;
  a {
    display: inline-block;
    border-radius: 5px;
    background: $KKT_SEMI_BLUE;
    padding: 10px 30px;
    @media (hover: hover) {
      &:hover {
        background: $KKT_DARK_BLUE;
      }
      &:active {
        background: $KKT_DARK_BLUE;
      }
    }
  }
  &-label {
    @include KKT_FOOTER_FONTSTYLE;
    margin-top: 7px;
    color: $KKT_LIGHT_BROWN;
  }
}
