/******************************************************************
 * Navigation Styles
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

#kkt-navigation {
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: $KKT_WHITE;
  width: $NAVIGATION_FRAME_WIDTH;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  ul {
    @include flattenList
  }
  .kkt-navigation-scroll-wrapper {
    padding: $NAVIGATION_FRAME_PADDING;
    box-sizing: border-box;
    min-height: 100%;
    position: relative;
  }
  .kkt-navigation-logo {
    margin-top: 20px;
    img {
      width: 100%
    }
  }

  .kkt-navigation-main {
    margin-top: 70px;
    margin-bottom: 70px;
    &-primary {
      @include KKT_MAIN_NAV_ITEM();
    }
    &-secondary {
      position: fixed;
      top: 0;
      left: $NAVIGATION_FRAME_WIDTH;
      width: 0; // updated via SecondaryNavLayer.ts
      height: 100%;
      background-color: rgba($KKT_WHITE, 0.95);
      overflow: hidden; // updated via SecondaryNavLayer.ts
      ul {
        @include KKT_MAIN_NAV_ITEM();
        padding: $NAVIGATION_FRAME_PADDING;
        padding-top: 0;
        box-sizing: border-box;
        width: $NAVIGATION_FRAME_WIDTH; // updated via SecondaryNavLayer.ts
      }
    }
  }

  .kkt-navigation-meta {
    position: absolute;
    bottom: $NAVIGATION_FRAME_PADDING;
    left: $NAVIGATION_FRAME_PADDING;
    right: $NAVIGATION_FRAME_PADDING;
    font-size: 0;
    overflow: hidden;
    .kkt-navigation-google-reviews {
      background: $KKT_DARK_BLUE;
      border-radius: 5px;
      text-align: center;
      padding: 1rem;
      padding-top: 1.5rem;
      @include ARTICLE_SECTION_ELEMENT_MARGIN(margin-bottom);
      &-reviews {
        position: relative;
        &:before {
          position: absolute;
          content: " ";
          background-image: url($KKT_QUOTE_ICON);
          background-size: contain;
          width: 81px * 0.75;
          height: 54px * 0.75;
          top: -1rem;
          left: -10px;
        }
        &-buttons {
          position: absolute;
          bottom: 1rem;
          width: 100%;
          z-index: 1;
          &-prev, &-next {
            position: absolute;
            cursor: pointer;
            width: 20px;
            height: 20px;
            background-image: url($KKT_SMALL_ARROW_ICON);
            background-position: center;
            background-repeat: no-repeat;
            top: 0;
            left: -0.5rem;
            transform: scaleX(-1);
            border-radius: 100%;
            background-color: $KKT_SEMI_BLUE;
            @include HOVER_EFFECT {
              transform: scaleX(-1.2) scaleY(1.2);
            }
          }
          &-next {
            left: unset;
            right: -0.5rem;
            transform: scaleX(1);
            @include HOVER_EFFECT {
              transform: scaleX(1.2) scaleY(1.2);
            }
          }
        }
        ul {
          white-space: nowrap;
          li {
            display: inline-block;
            width: 100%;
            white-space: normal;
            padding-left: 1rem;
            padding-right: 1rem;
            vertical-align: middle;
            a {
              blockquote {
                .kkt-navigation-google-reviews-review-quote {
                  @include KKT_MAIN_NAVIGATION_REVIEW_QUOTE_FONTSTYLE;
                  color: $KKT_BACKGROUND;
                  font-style: italic;
                }
                .kkt-navigation-google-reviews-review-author {
                  @include KKT_FOOTER_FONTSTYLE;
                  margin-top: 1em;
                  color: $KKT_BACKGROUND;
                }
              }
            }
          }
        }
      }
      &-general {
        border-top: 1px solid rgba($KKT_LIGHT_BROWN, 0.5);
        margin-top: 1rem;
        padding-top: 1rem;
        a {
          display: block;
        }
        &-label {
          @include KKT_FOOTER_FONTSTYLE;
          margin-top: 7px;
          color: $KKT_LIGHT_BROWN;
        }
      }
    }

    &-socialmedia {
      margin-top: $NAVIGATION_FRAME_PADDING;
      li {
        @include HOVER_SCALE_EFFECT;
        display: inline-block;
        margin-left: 12px;
        &:first-child {
          margin-left: unset;
        }
      }
    }

    &-language {
      font-size: 0;
      a {
        @include KKT_FOOTER_FONTSTYLE();
      }
    }

    &-legal {
      font-size: 0;
      margin-top: $NAVIGATION_FRAME_PADDING;
      li {
        display: inline-block;
        margin-left: 12px;
        &:first-child {
          margin-left: unset;
        }
        a {
          @include KKT_FOOTER_FONTSTYLE();
        }
      }
    }
  }
}
