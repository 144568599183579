/******************************************************************
 * KKT ContactBox
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-contactbox {
  width: 90%;
  @include FLUID_PROPERTY(max-width, 640, 750, $KKT_BREAKPOINT_L);
  h2 {
    display: none;
  }
  > div {
    .kkt-contactbox-header {
      background-color: $KKT_BACKGROUND;
      display: flex;
      align-items: center;
      margin-bottom: 2px;
      @include FLUID_PROPERTY(padding, 20, 40);
      box-sizing: border-box;
      img {
        width: 25%;
      }
      div {
        @include FLUID_PROPERTY(padding-left, 20, 40);
        box-sizing: border-box;
        h3 {
          @include KKT_MAIN_TEASER_HEADLINE_FONTSTYLE;
          @include FLUID_PROPERTY(margin-bottom, 10, 15);
        }
        h4 {
          @include KKT_FIGURE_P_FONTSTYLE;
        }
      }
    }
    .kkt-contactbox-main {
      position: relative;
      &-entry {
        pointer-events: all;
        &[data-showcontent="true"] {
          background-color: $KKT_WHITE;
          .kkt-contactbox-main-entry-name {
            color: $KKT_DARK_BLUE;
            background-color: $KKT_WHITE;
          }
          .kkt-contactbox-main-entry-value {
            display: flex;
          }
        }
        &-name {
          @include KKT_LABELBUTTON_FONTSTYLE;
          color: $KKT_GRAY;
          width: 33%;
          text-align: center;
          @include FLUID_PROPERTY(padding-top, 20, 30);
          @include FLUID_PROPERTY(padding-bottom, 20, 30);
          box-sizing: border-box;
          background-color: $KKT_BACKGROUND;
          margin-top: 2px;
          cursor: pointer;
          @include HOVER_EFFECT() {
            color: $KKT_DARK_BLUE;
            background-color: $KKT_WHITE;
          }
        }
        &-value {
          display: none;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: calc(33% + 2px);
          width: calc(67% - 2px);
          height: 100%;
          background-color: $KKT_WHITE;
          &-content {
            display: block;
            @include FLUID_PROPERTY(padding, 20, 40);
            @include KKT_ARTICLE_P_FONTSTYLE;
            text-align: center;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
