/******************************************************************
 * KKT-ARTICLE-SECTION
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-article-section {
  @include ARTICLE_SECTION_MARGIN;
  &-headline {
    h2 {
      @include KKT_ARTICLE_H2_FONTSTYLE;
      @include ARTICLE_DEFAULT_CONTENT_WIDTH;
      position: relative;
      text-align: center;
      &:before {
        @include ARTICLE_BLOCK_SECTION_MARGIN(margin-bottom);
        content: " ";
        width: 100px;
        height: 3px;
        display: block;
        margin: auto;
        background-color: $KKT_WHITE;
      }
    }
    h3 {
      @include KKT_ARTICLE_H3_FONTSTYLE;
      @include ARTICLE_TEXT_CONTENT_WIDTH;
      @include ARTICLE_FLUID_PROPERTY(margin-top, 10, 15, 15, 20);
      text-align: center;
      color: $KKT_BROWN;
    }
  }
  .kkt-text {
    @include ARTICLE_TEXT_CONTENT_WIDTH;
    @include KKT_WRITER_STYLE;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-quote {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-google-review-stars {
    @include ARTICLE_TEXT_CONTENT_WIDTH;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-image {
    @include ARTICLE_BLOCK_SECTION_MARGIN;
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
  }
  .kkt-vitealist {
    @include ARTICLE_BLOCK_SECTION_MARGIN;
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
  }
  .kkt-imagegallery {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-logogrid {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-accordion {
    @include ARTICLE_TEXT_CONTENT_WIDTH;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-accordion-wide {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
  }
  .kkt-serviceteasers {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-reviews {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-openings {
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-factlist {
    @include ARTICLE_TEXT_CONTENT_WIDTH;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-factlist-wide {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
  }
  .kkt-patientteasers {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
  .kkt-serviceinfos {
    @include ARTICLE_DEFAULT_CONTENT_WIDTH;
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }

  .kkt-contact {
    @include ARTICLE_BLOCK_SECTION_MARGIN;
  }
}
