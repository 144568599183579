/******************************************************************
 * Write Style
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

@mixin KKT_WRITER_STYLE() {
  @include KKT_ARTICLE_P_FONTSTYLE;
  strong {
    font-weight: $KKT_FONT_WEIGHT_REGULAR;
    color: $KKT_DARK_BLUE;
  }
  a {
    color: $KKT_DARK_BLUE;
    font-weight: $KKT_FONT_WEIGHT_REGULAR;
    border-bottom: 1px dotted $KKT_DARK_BLUE;
    @include HOVER_EFFECT() {
      border-bottom: 1px solid $KKT_DARK_BLUE;
    }
  }
  p {
    margin-top: 1em;
    &:first-child {
      margin-top: 0;
    }
  }
  ul, ol {
    margin-top: 2em;
    margin-bottom: 2em;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    li::marker {
      color: $KKT_DARK_BLUE;
    }
    li {
      margin-top: 0.75em;

      &:first-child {
        margin-top: 0;
      }
    }
  }
  ul {
    padding-left: 1.3em;
    list-style-type: disc !important;
    li::marker {
      margin-left: 0.1em;
    }
    li {
      padding-left: 0.2em;
    }
  }
  ol {
    padding-left: 1em;
    li::marker {
      font-size: 0.8em;
      font-weight: $KKT_FONT_WEIGHT_SEMIBOLD;
    }
    li {
      padding-left: 0.5em;
    }
  }

}
