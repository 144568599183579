/******************************************************************
 * Quick-Contact-Buttons Styles
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-quick-contact-buttons {
  text-align: left;
  ul {
    @include flattenList;
    white-space: nowrap;
    li {
      @include KKT_LABELBUTTON_FONTSTYLE;
      font-weight: $KKT_FONT_WEIGHT_REGULAR;
      display: inline-block;
      margin-left: 10px;
    }
  }
  .kkt-phone-button {
    @include HOVER_SCALE_EFFECT()
  }
  .kkt-mail-button {
    @include HOVER_SCALE_EFFECT()
  }
  .kkt-location-button {
    @include HOVER_SCALE_EFFECT()
  }
  .kkt-internallink-button {
    @include HOVER_SCALE_EFFECT()
  }
}
