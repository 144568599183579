/******************************************************************
 * KKT-serviceteasers
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

.kkt-serviceteasers {
  @include flattenList;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-auto-rows: 1fr;
  hyphens: auto;
  @include FLUID_PROPERTY(gap, 20, 40, $KKT_BREAKPOINT_XS, $KKT_BREAKPOINT_L);
  @include RESPOND_TO(550) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include RESPOND_TO($KKT_BREAKPOINT_L) {
    grid-template-columns: repeat(3, 1fr);
    @include FLUID_PROPERTY(gap, 40, 80, $KKT_BREAKPOINT_L);
  }
  @include RESPOND_TO($KKT_BREAKPOINT_XL) {
    hyphens: none;
  }
  &-entry {
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    background-color: rgba($KKT_WHITE, 0.75);
    &-image {
      display: block;
      position: relative;
      padding-bottom: 70%;
      overflow: hidden;
      @media (hover: hover) {
        &:hover {
          opacity: 1;
          text-decoration: none;
          img {
            transform: scale(1.05);
          }
        }
      }
      &:active {
        opacity: 1;
        text-decoration: none;
        img {
          transform: scale(1.05);
        }
      }
      img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform 1s $EASE_OUT_QUINT;
      }
    }
    &-content {
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-top);
      @include ARTICLE_SECTION_ELEMENT_MARGIN(padding-bottom);
      &-primarylink {
        @include KKT_ARTICLE_H4_FONTSTYLE;
        margin-bottom: 1rem;
        a {
          color: $KKT_DARK_BLUE;
          text-align: center;
          display: block;
        }
      }
      &-secondarylinks {
        @include flattenList;
        opacity: 0.5;
        min-height: 2rem;
        a {
          @include KKT_ARTICLE_H5_FONTSTYLE;
          display: block;
          text-align: center;
        }
      }
    }
    &-cta {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(50%);
      @include KKT_LIGHT_LABEL_BUTTON;
    }
  }
}
